<template>
  <Header title="Často kladené otázky" />
  <div id="faqs" class="container">
    <div class="accordion accordion-flush" id="accordionPanelsStayOpenExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="panelsStayOpen-headingOne">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
            Prečo appka odmieta moje evidenčné číslo pri aktivácii?
          </button>
        </h2>
        <div id="panelsStayOpen-collapseOne" data-bs-parent="#accordionPanelsStayOpenExample" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
          <div class="accordion-body">
            Pred prvou aktiváciou je nutné navštíviť ktorékoľvek pracovisko NTS SR a požiadať obsluhu o povolenie Vášho EVČ pre appku. Zároveň odporúčame skontrolovať mobilné tel. číslo v informačnom systéme NTS SR.<br>
            Každá ďalšia aktivácia (nové zariadenie, reinštalácia appky a pod.) je už možná bez nutnosti kontaktovať NTS SR.<br>
            Appka pozná len EVČ darcov z NTS SR, s darcami mimo NTS SR appka nepracuje.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
            Ako si resetovať zabudnutý PIN?
          </button>
        </h2>
        <div id="panelsStayOpen-collapseTwo" data-bs-parent="#accordionPanelsStayOpenExample" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
          <div class="accordion-body">
            Na obrazovke s PINom ťuknite na odkaz Aktivovať el. preukaz darcu.
            Postupujte rovnako ako pri prvej aktivácii, nový PIN môžete zadať po overení aktivačnej SMS.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="panelsStayOpen-headingThree">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
            Prečo sa zobrazuje len jeden parameter krvného obrazu?
          </button>
        </h2>
        <div id="panelsStayOpen-collapseThree" data-bs-parent="#accordionPanelsStayOpenExample" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
          <div class="accordion-body">
            NTS SR má povinnosť vyšetrovať kompletný krvný obraz len raz ročne. Táto možnosť sa využíva pri výjazdových odberoch, kde sa vyšetruje len Hgb z prsta. Ak chodíte výlučne na výjazové odbery, kompletný krvný obraz uvidíte len pri jednom odbere za rok.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="panelsStayOpen-headingFour">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
            Prečo mi neprichádza aktivačná SMS?
          </button>
        </h2>
        <div id="panelsStayOpen-collapseFour" data-bs-parent="#accordionPanelsStayOpenExample" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
          <div class="accordion-body">
            V informačnom systéme NTS SR máte pravdepodobne chybne zadané tel. číslo. Kontaktuje Vaše domovské pracovisko a požiadajte ich o kontrolu a opravu mobilného tel. čísla.<br>
            Opravy údajov sa s appkou synchronizujú raz denne, ďalsí pokus o aktiváciu vykonajte až na ďalší deň.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header'

export default {
  name: 'Faqs',
  components: {
    Header
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../functions.scss";

#faqs {
  text-align: left;
  .accordion {
    .accordion-item {
      .accordion-button {
        font-weight: bold;
        font-size: rem(19);
        padding-right: 30px;
        &::after {
          position: absolute;
          right: 5px;
          top: 50%;
          transform: translate(0 , -50%);
        }
        &:focus {
          box-shadow: none;
        }
        &:not(.collapsed) {
          box-shadow: var(--bs-accordion-btn-focus-box-shadow);
          &::after {
            transform: translate(0, -50%) rotate(180deg);
          }
        }
      }
    }
  }
}
</style>
